@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom base styles here */
@layer base {
  /* default theme */

  html {
    -webkit-tap-highlight-color: transparent;    
  }

  html,
  body {
    @apply antialiased bg-brand-light text-brand-muted font-body font-normal;
  }

  .required {
    @apply text-red-500;
  }

  p {
    @apply mb-5;
  }

  p:last-of-type {
    @apply mb-0;
  }

  p>strong {
    @apply font-semibold;
  }

  p>a {
    @apply transition text-brand;
  }

  p>a:hover {
    @apply text-brand-dark;
  }

  .h-inherit {
    height: inherit;
  }

  .order-list-enable ol {
    list-style: decimal;
    @apply ltr:pl-4 rtl:pr-4 lg:ltr:pl-8 lg:rtl:pr-8 space-y-1.5;
  }

  button:focus {
    outline: 0px solid !important;
  }
}

.debug-red {
  border: 1px solid red;
}

.debug-blue {
  border: 1px solid blue;
}

.hightlighted-bar {
  background-color: #0a1c56;
}

/* Header Menu */
#siteHeader .header-location,
#siteHeader .header-tools {
  @apply invisible;
}

#siteHeader.is-scrolling .innerSticky {
  @apply top-0 transition duration-200 ease-in-out shadow-header;
}

#siteHeader.header-four.is-scrolling .innerSticky {
  background-color: white !important;
}

#siteHeader.is-scrolling .innerSticky .header-location {
  @apply visible mt-2 absolute z-20;
}

#siteHeader.is-scrolling .innerSticky .header-tools {
  @apply visible pb-3 relative z-20;
}

#siteHeader.is-scrolling .innerSticky .main-menu {
  @apply pt-2;
}

#siteHeader.is-scrolling .innerSticky .menu-button {
  @apply pb-5;
}

.selected-pair {
  overflow: hidden;
}

.step-divider {
  @apply border-gray-300 xs:p-[16px] lg:p-[18px] right-0 xs:top-[13px] lg:top-[15px];
  display: inline-block;
  border-width: 0 1px 1px 0;
  transform: translateY(15.5px) rotate(-45deg) skew(-15deg, -15deg) translateX(18px);
  background-color: #fff;
  position: absolute;
  z-index: 0;
}

.page-title {
  @apply font-cardo;
  text-align: center;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.subMenu {
  top: calc(100% + 21px);
}

/* for header one */
.header-one .headerMenu .menuItem>a::before {
  bottom: -22px;
}

.subMenu li:hover>.subMenuChild {
  @apply visible opacity-100 -top-3;
}

.headerMenu .menuItem:hover>.subMenu {
  visibility: visible;
  top: calc(100% + 2px);
}

.header-one .headerMenu .menuItem:hover>.subMenu {
  top: calc(100% + 10px);
}

/* Mobile Drawer Menu */
.mobile-menu>li>div>a {
  @apply text-15px py-3.5 font-medium;
}

.mobile-menu li li {
  @apply text-sm;
}

.mobile-menu li li div>a {
  @apply relative py-2.5 text-opacity-90;
}

.mobile-menu li>ul>li>div>a:before {
  content: '';
  height: 1px;
  @apply flex absolute top-1/2 ltr:left-1 rtl:right-1 md:ltr:left-3 md:rtl:right-3 w-[7px] bg-brand-dark;
}

/* For login and signup switch */
.switch {
  height: 22px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

main input:checked+.slider {
  background-color: #e5e9e8;
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Others Component */
.product-count-button-position {
  @apply absolute bottom-3 lg:bottom-2 w-full ltr:left-1 rtl:right-1 flex justify-center px-2.5 lg:px-3 xl:px-4;
}

.product-count-button-position>button {
  @apply ltr:ml-auto rtl:mr-auto;
}

.product-count-button-position>div {
  @apply w-[calc(100%-28px)] xl:w-[calc(100%-50px)];
}

.after-item-opacity:after {
  @apply w-[16%] 2xl:w-[14%] 3xl:w-[12%] 4xl:hidden;
}

@media (min-width: 1280px) {
  .after-item-opacity:after {
    @apply absolute top-0 h-full ltr:right-0 rtl:left-0;
    background-image: linear-gradient(to right,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 1));
    content: '';
    z-index: 5;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 21;
}

.overlay.open,
.overlay.input-focus-overlay-open,
.overlay.open-search-overlay,
.category-dropdown-menu>li:hover>div {
  opacity: 1;
  visibility: visible;
}

.bg-progress-striped {
  background-image: linear-gradient(-45deg,
      rgba(255, 255, 255, 0.3) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 75%,
      transparent);
  background-size: 14px 14px;
}

.scroll-snap-type-y-mandatory {
  scroll-snap-type: y mandatory;
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

.faq-banner-area {
  background-position: 20% 0;
}

@media (min-width: 768px) {
  .faq-banner-area {
    background-position: center center;
  }
}

@media (min-width: 1024px) {
  .minimal-main-content {
    width: calc(100% - 320px);
  }
}

@media (min-width: 1280px) {
  .minimal-main-content {
    width: calc(100% - 370px);
  }

  .trendy-main-content {
    width: calc(100% - 320px);
  }
}

@media (min-width: 1536px) {
  .trendy-main-content {
    width: calc(100% - 370px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hero-banner-six {
    background-position: 15% center;
  }

  .page-header-banner {
    background-position: 17% center;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .hero-banner-six {
    background-position: 42% center;
  }

  .page-header-banner {
    background-position: 80% center;
  }
}

@media (min-width: 1280px) and (max-width: 1535px) {
  .hero-banner-six {
    background-position: 35% center;
  }
}

/* Breadcrumb truncate last child */
.jewelBreadcrumb li:last-child {
  @apply font-medium truncate;
}

.style-variant-white .jewelBreadcrumb ol>li>a>svg,
.style-variant-white .jewelBreadcrumb ol>li>a {
  @apply text-brand-light text-opacity-70;
}

.style-variant-white .jewelBreadcrumb ol>li>svg {
  @apply text-opacity-50 text-brand-light;
}

.style-variant-white .jewelBreadcrumb ol>li:last-of-type>a {
  @apply text-brand-light;
}

.header-two.sticky-header.is-scrolling .navbar-logo,
.header-five.sticky-header.is-scrolling .navbar-logo {
  @apply -mt-1;
}

.header-five.sticky-header.is-scrolling .innerSticky .categories-header-button {
  display: none;
}

/* sticky site header styles */
@media (min-width: 1024px) {
  .sticky-header.is-scrolling .navbar {
    @apply fixed top-0 z-30 w-full shadow-header;
  }

  .sticky-header.is-scrolling .delivery-address,
  .sticky-header .navbar .sticky-search {
    @apply hidden;
  }

  .sticky-header.is-scrolling .navbar .sticky-search {
    @apply flex;
  }

  .sticky-header .navbar-logo,
  .sticky-header .navbar .navbar-right {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  .sticky-header.is-scrolling .navbar-logo,
  .sticky-header.is-scrolling .navbar .navbar-right {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    @apply !w-auto opacity-100;
  }

  .sticky-header.is-scrolling .headerMenu {
    @apply w-auto translate-x-0;
  }

  .header-two.sticky-header.is-scrolling .headerMenu,
  .header-five.sticky-header.is-scrolling .headerMenu {
    @apply ltr:ml-8 rtl:mr-8;
  }
}

.sticky-header .top-bar.active-mobile-search .logo {
  @apply hidden;
}

#siteHeader.active-mobile-search .innerSticky .top-bar-search {
  @apply block;
}

.subscribe-btn:hover {
  box-shadow: none !important;
}

.registration img {
  height: 100%;
  object-fit: cover;
}

/* Order page */
/* order list table */
.order-list-table-wraper {
  @apply overflow-x-auto;
}

.order-list-table table th,
.order-list-table table td {
  border: none;
}

.order-list-table.rc-table thead td,
.order-list-table.rc-table thead th {
  text-align: left;
}

.order-list-table .rc-table-content {
  @apply !overflow-visible;
  border-color: transparent;
}

.order-list-table.rc-table .rc-table-thead {
  background: #f3f6f9;
  border-radius: 3px;
  opacity: 1;
}

.order-list-table.rc-table thead th {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.order-list-table.rc-table th,
.order-list-table.rc-table td {
  padding: 9px 18px;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}

.order-list-table.rc-table thead th {
  padding-top: 12px;
  padding-bottom: 12px;
}

.order-list-table.rc-table td {
  border-bottom: 1px solid #e7ecf0;
}

.order-list-table.rc-table tr td {
  transition: background-color 0.5s;
}

.order-list-table.rc-table tr:hover td {
  background-color: #f3f6f9;
}

.bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.actions_button_group svg {
  width: 100%;
}

@media (max-width: 767px) {
  .order-list-table-wraper {
    position: relative;    
  }

  .order-list-table.rc-table th:not(.operations-cell),
  .order-list-table.rc-table td:not(.operations-cell) {
    white-space: nowrap;
  }

  .order-list-table.rc-table th:first-child,
  .order-list-table.rc-table td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }

  .order-list-table.rc-table th.id-cell {
    background: #f3f6f9;
  }

  .order-list-table.rc-table td.operations-cell {
    padding: 0;
  }
}

/* Address box */

.address__box input[type='radio']:checked~label {
  border-color: #02b290;
}

@media (min-width: 1024px) {
  .address__box:hover .address__actions {
    opacity: 1;
  }
}

/* Checkout details page accordion */
.accordion__panel.collapsed .accordion__content {
  display: none;
}

.accordion__panel.expanded .accordion__content {
  display: block;
  background-color: #ffffff;
}

.accordion__panel.collapsed .accordion__button {
  background-color: #f8f9fa;
}

.address__box:hover .address__actions {
  opacity: 1;
}

.borderColor::before {
  content: '';
  position: absolute;
  left: 2px;
  width: 3px;
  background: #02b290;
  @apply h-6 top-3 2xl:top-2.5;
}

.registration>div {
  height: 100%;
}

.StripeElement {
  box-sizing: border-box;
  height: 310px;
  padding: 15px;
  border-radius: 4px;
  background-color: white;
  -webkit-transition: border 150ms ease;
  transition: border 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.scrollbar {
  scrollbar-width: none;
  -webkit-scrollbar-width: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.wishlist-card:last-child {
  border-bottom: none;
}

.order-search {
  @apply bg-white !important;
  @apply rounded-md !important;
}

.order-icon-color {
  color: rgba(0, 0, 0, 0.3);
  height: 100%;
}

.checkout .save-change-button {
  display: none;
}

/* rc rate  */
.rc-rate-star-full {
  @apply text-[#F3B81F];
}

/* antique banner  */
@media (max-width: 768px) {
  .antique-banner {
    background-position: left bottom -100px !important;
  }
}

/* fix input search */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/*Form*/
@layer components {
  .font-timesnewroman {
    font-family: 'Times New Roman', Times, serif;
  }

  .jwl-input-error {
    @apply border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6;
  }

  .jwl-input-focus {
    @apply ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6;
  }

  .jwl-input-active {
    @apply ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6;
  }

  .jwl-input-sm {
    @apply font-body rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm;
  }

  .jwl-input {
    @apply font-body rounded-md border-0 px-3 py-1.5 h-11 md:h-12 text-gray-900 shadow-sm;
  }

  .btn2-xs {
    @apply rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }

  .btn2-sm {
    @apply bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }

  .btn2-md {
    @apply rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }

  .btn2-xl {
    @apply rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }

  .btn2-xl2 {
    @apply rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }

  .grab_widget span.installment_amount {
    @apply !font-normal;
  }  

  .rc-slider-mark {
    @apply !flex !items-center !justify-between !relative;
  }

  /** hide the first element **/
  .rc-slider-mark .rc-slider-mark-text:first-child {
    @apply !hidden;
  }

  .rc-slider-mark .rc-slider-mark-text {
    @apply block !text-center justify-center;
  }

  .default-billing, .default-shipping {
    @apply !border-green-500;
  }
}

.Toastify {
  z-index: 9999999;
}

.Toastify .Toastify__toast {
  @apply !bg-white !font-outfit;
}

.Toastify .Toastify__toast .Toastify__toast-body,
.Toastify .Toastify__toast .Toastify__close-button {
  @apply !text-gray-800 !font-outfit;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  @apply !bg-yellow-50 !text-white;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply !bg-green-300 !text-white;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  @apply !bg-orange-200 !text-white;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  @apply !bg-red-300 !text-white;
}

.rc-slider-disabled {
    @apply !bg-white !cursor-not-allowed;
}

.certificate {
    width:570px;height:890px;
    position: relative;
    font-size: 13px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;         
    color: #505050;
}

.certificate .stone-details {
    position:absolute;
    width: 300px;
    height: 400px;
    left: 140px;
}

.certificate .stone-info-blk {
    position:absolute;
    top: 150px;
}        

.certificate .stone-info-blk td.grow {
    width: 100px;    
}

.certificate .stone-info-blk td.grow div {    
    border-bottom: 1px solid #808080;
    height: 13px;
}

.certificate .stone-info-blk .row-title {
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    font-family:'Times New Roman', Times, serif;
    text-align: right;
    padding: 2px 0px;
    color: #808080;
}

.certificate .stone-info-blk .grow {
    margin-bottom: 8px;
    text-align: left;
}

.certificate .photograph {
    position: absolute;
    left: 80px;
    bottom: 260px;            
}

.certificate .comments h4 {
    margin-bottom: 4px;
    font-family:'Times New Roman', Times, serif !important;
}

.certificate .comments {
    position: absolute;
    left: 110px;
    bottom: 230px;
    font-family:'Times New Roman', Times, serif !important;
    font-size: 14px;
    color: #808080;
}        